import React from "react";
import { Button, Card, Col, Input, Row, Space, Table, Tag } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useFirms } from "./firms.hook";
import dayjs from "dayjs";
import FirmCreateModal from "../../components/FirmCreateModal";

const Firms = () => {
  const { firms, loading, fetchFirms } = useFirms();
  const [search, setSearch] = React.useState("");
  const [createModalOpen, setCreateModalOpen] = React.useState(false);

  let searchResult = search
    ? firms.filter(
        (item: any) =>
          item.practiceName.toLowerCase().includes(search.toLowerCase()) ||
          item.postCode.toLowerCase().includes(search.toLowerCase()),
      )
    : firms;

  return (
    <Card>
      <FirmCreateModal
        open={createModalOpen}
        onCancel={() => setCreateModalOpen(false)}
        triggerRefresh={() => {
          fetchFirms();
        }}
      />
      <h2>Firms</h2>
      <Row gutter={[16, 16]} justify="space-between">
        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
          <Input.Search
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{ marginBottom: 16 }}
            placeholder="Search for company name or postal code..."
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={6}
          xl={4}
          xxl={3}
          style={{ textAlign: "right" }}
        >
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => {
              setCreateModalOpen(true);
            }}
          >
            Add Firm
          </Button>
        </Col>
      </Row>
      <Table
        loading={loading}
        scroll={{ x: true }}
        dataSource={searchResult}
        columns={[
          {
            title: "Company Name",
            dataIndex: "companyName",
            key: "id",
            render: (companyName, record: any) => (
              <a href={`/firms/${record.slug}`}>{record.practiceName}</a>
            ),
            sorter: {
              compare: (a, b) => a.practiceName.localeCompare(b.practiceName),
              multiple: 3,
            },
          },
          {
            title: "Postal Code",
            dataIndex: "postCode",
            key: "postCode",
          },
          {
            title: "Category",
            dataIndex: "FirmCategories",
            key: "category",
            render: (categories) => (
              <span>
                {categories
                  ?.map((category: any) => category?.category?.title)
                  .join(", ") || ""}
              </span>
            ),
            onFilter: (value, record) => {
              return record.FirmCategories.some(
                (item: any) => item.category.name === value,
              );
            },
            sorter: (a, b) => a.FirmCategories.length - b.FirmCategories.length,
            filters: [
              { text: "Mixed Use", value: "mixed" },
              { text: "Residential", value: "residential" },
              { text: "Education", value: "education" },
              { text: "Office", value: "office" },
              { text: "Retail", value: "retail" },
              { text: "Industrial", value: "industrial" },
              { text: "Infrastructure", value: "infrastructure" },
              { text: "Hospitality", value: "hospitality" },
            ],
          },
          {
            title: "Subscription",
            render: (company) => {
              const isSubscribed =
                company?.subscriptions.find((item: any) =>
                  dayjs(item.endDate).isAfter(dayjs()),
                ) || company.manualSubscription;
              const isManual = company.manualSubscription;
              return (
                <span>
                  {isSubscribed ? (
                    <>
                      <CheckOutlined
                        style={{
                          color: "green",
                        }}
                      />
                      {isManual && (
                        <Tag style={{ marginLeft: 4 }} color="green">
                          Free
                        </Tag>
                      )}
                    </>
                  ) : (
                    <CloseOutlined
                      style={{
                        color: "red",
                      }}
                    />
                  )}
                </span>
              );
            },
            onFilter: (value, record) => {
              return record.subscriptions.some(
                (item: any) => dayjs(item.endDate).isAfter(dayjs()) === value,
              );
            },
            filters: [
              { text: "Subscribed", value: true },
              { text: "Not Subscribed", value: false },
            ],
          },
          {
            title: "Subscription End Date",
            dataIndex: "subscriptions",
            render: (subscriptions) => {
              const subscription = subscriptions.find((item: any) =>
                dayjs(item.endDate).isAfter(dayjs()),
              );
              return (
                <span>
                  {subscription
                    ? dayjs(subscription.endDate).format("DD/MM/YYYY")
                    : "-"}
                </span>
              );
            },
          },
          {
            title: "Created At",
            dataIndex: "createdAt",
            render: (createdAt: string) => (
              <span>{dayjs(createdAt).format("DD/MM/YYYY HH:mm:ss")}</span>
            ),
          },
          {
            title: "Aksiyon",
            render: (value) => (
              <Space size={8}>
                <Button
                  href={`/firms/${value.slug}`}
                  type="primary"
                  onClick={() => {}}
                >
                  <EditOutlined /> Edit
                </Button>
              </Space>
            ),
          },
        ]}
      />
    </Card>
  );
};

export default Firms;
