import React, { useState } from "react";
import { Button, Card, Col, Descriptions, Image, Row, Tag } from "antd";
import styles from "./FirmProfile.module.css";
import dayjs from "dayjs";
import { EditOutlined } from "@ant-design/icons";
import FirmUpdateModal from "../FirmUpdateModal";
type Props = {
  company: {
    companyName: string;
    postCode: string;
    address: string;
    fullAddress: string;
    FirmCategories: {
      category: {
        id: number;
        title: string;
        name: string;
      };
    }[];
    category: {
      id: number;
      title: string;
      name: string;
    };
    city: string;
    country: string;
    createdAt: string;
    updatedAt: string;
    description: string;
    firstName: string;
    lastName: string;
    latitude: number;
    longitude: number;
    phoneNumber: string;
    practiceName: string;
    projectEmail: string;
    projectImages: string[];
    website: string;
    subscription: boolean;
    subscriptions: {
      createdAt: string;
      endDate: string;
      firmId: number;
      id: number;
      plan: string;
      startDate: string;
      status: string;
      stripeSubscriptionId: string;
      updatedAt: string;
      userId: number;
    }[];
  };
};

const FirmProfile = (props: Props) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const { company } = props;
  const isSubscribed = company?.subscriptions.find((item) =>
    dayjs(item.endDate).isAfter(dayjs()),
  );

  const isCancelled = company?.subscriptions.find(
    (item) => item.status === "canceled",
  );

  return (
    <>
      <Card
        title={<>{company?.companyName}</>}
        extra={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              icon={<EditOutlined />}
              type="primary"
              style={{ marginLeft: 16 }}
              onClick={() => setEditModalOpen(true)}
            >
              Edit Company
            </Button>
          </div>
        }
      >
        <Descriptions
          extra={
            <>
              {company?.subscriptions.map((subscription) => {
                if (
                  subscription.status === "paid" ||
                  subscription.status === "canceled"
                ) {
                  return (
                    <>
                      <strong>Subscription Period - </strong>
                      <span>
                        {dayjs(subscription.startDate).format("DD/MM/YYYY")} -{" "}
                        {dayjs(subscription.endDate).format("DD/MM/YYYY")}
                      </span>
                      {" - "}
                      <strong>{subscription.plan.toUpperCase()}</strong>
                    </>
                  );
                }

                return null;
              })}
            </>
          }
          title="Company Details"
        >
          <Descriptions.Item label="Company Name">
            {company?.companyName}
          </Descriptions.Item>
          <Descriptions.Item label="Post Code">
            {company?.postCode}
          </Descriptions.Item>
          <Descriptions.Item label="Address">
            {company?.address}
          </Descriptions.Item>
          <Descriptions.Item label="Full Address">
            {company?.fullAddress}
          </Descriptions.Item>
          <Descriptions.Item label="Categories">
            {company?.FirmCategories?.map(
              (category: any) => category?.category?.title,
            ).join(", ")}
          </Descriptions.Item>
          <Descriptions.Item label="City">{company?.city}</Descriptions.Item>
          <Descriptions.Item label="Country">
            {company?.country}
          </Descriptions.Item>
          <Descriptions.Item label="Created At">
            {dayjs(company?.createdAt).format("DD/MM/YYYY - HH:mm:ss")}
          </Descriptions.Item>
          <Descriptions.Item label="Updated At">
            {dayjs(company?.updatedAt).format("DD/MM/YYYY - HH:mm:ss")}
          </Descriptions.Item>
          <Descriptions.Item label="First Name">
            {company?.firstName}
          </Descriptions.Item>
          <Descriptions.Item label="Last Name">
            {company?.lastName}
          </Descriptions.Item>
          <Descriptions.Item label="Latitude">
            {company?.latitude}
          </Descriptions.Item>
          <Descriptions.Item label="Longitude">
            {company?.longitude}
          </Descriptions.Item>
          <Descriptions.Item label="Phone Number">
            {company?.phoneNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Practice Name">
            {company?.practiceName}
          </Descriptions.Item>
          <Descriptions.Item label="Project Email">
            {company?.projectEmail}
          </Descriptions.Item>
          <Descriptions.Item label="Website">
            <a
              target="_blank"
              rel="noreferrer"
              href={
                company?.website?.startsWith("https")
                  ? company?.website
                  : `https://${company?.website}`
              }
            >
              {company?.website}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="Subscription">
            {isSubscribed ? (
              <Tag color="green">Subscribed</Tag>
            ) : (
              <Tag color="red">Not Subscribed</Tag>
            )}
            {isCancelled && (
              <Tag color="orange" style={{ marginLeft: 0 }}>
                Cancelled
              </Tag>
            )}
          </Descriptions.Item>
          <Descriptions.Item span={24} label="Description">
            {company?.description}
          </Descriptions.Item>
          <Descriptions.Item label="Project Images">
            <Row gutter={[16, 16]}>
              {company?.projectImages?.map((image) => (
                <Col span={6}>
                  <div className={styles.imageWrapper}>
                    <Image
                      height={200}
                      src={image}
                      alt="project"
                      className={styles.image}
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <FirmUpdateModal
        open={editModalOpen}
        onCancel={() => setEditModalOpen(false)}
        company={company}
      />
    </>
  );
};

export default FirmProfile;
